body {
  background: #fff;
  background-image: url('./charlotte.jpg');
  background-position: center top;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
}

#x-nav-wrap-mobile .current-menu-item a {
  color: black;
}